import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { Slider } from "primereact/slider";
import httpClient from "../core/api";
import { Toast } from 'primereact/toast';
import ConfirmStatusModal from "./Modal/ConfirmStatusModal";
import useUserStore from "../../store/UserStore";
import Icon from "./Icon";
import InputError from "./InputError";
        
const StatusUpdateDialog = ({ visible, onHide ,onRefresh ,locationValue,laststatus}) => {
  const toast = useRef(null);
  const userinfo=useUserStore((state) => state.userInfo)
  const userlocation=userinfo?.location?.id;
  const setLoading = useUserStore((state) => state.setLoading)
  const [selectedOperationValue, setSelectedOperationValue] = useState(1);
  const [selectedCapacity, setSelectedCapacity] = useState(1);
  const [selectedPDCapacity, setSelectedPDCapacity] = useState(100);
  const [selectedstaff, setSelectedStaff] = useState(100);
  const [comment, setComment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedIssue, setSelectedIssue] = useState(6);
  const [selectedDays, setSelectedDays] = useState(0);
  const [showstatusModal,setshowStatusModal]=useState(false);
  const [locationName,setLocationName]=useState("");
  const [locations,setLocations]=useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState();
  const operationitems = [
    { name: "Open", value: 1, className: "option-open" },
    { name: "Limited", value: 2, className: "option-limited" },
    { name: "Closed", value: 3, className: "option-closed" },
    
  ];

  const capacityitemss = [
    { name: "Normal", value: 1, className: "option-normal" },
    { name: "Crowded", value: 2, className: "option-crowded" },
    { name: "Over Crowded", value: 3, className: "option-overcrowded" },
  ];
  const staffnumbers= [
    { name: "100%", value: 100, className: "option-100" },
    { name: "75%", value: 75, className: "option-75" },
    { name: "50%", value: 50, className: "option-50" },
    { name: "25%", value: 25, className: "option-25" },
    { name: "0%", value: 0, className: "option-0" },
  ];
  const pdcapacity= [
    { name: "100%", value: 100, className: "option-100" },
    { name: "75%", value: 75, className: "option-75" },
    { name: "50%", value: 50, className: "option-50" },
    { name: "25%", value: 25, className: "option-25" },
    { name: "0%", value: 0, className: "option-0" },
  ];

  const issues = [
    { name: "None", code:6},
    { name: "Due To Rain", code: 1 },
    { name: "Due To Snowfall", code: 2 },
    { name: "Due To Fire", code: 3 },
    { name: "Staff illness", code: 4 },
    { name: "Other", code: 5 },
  ];
  useEffect(() => {
    if (laststatus) {
      setSelectedCapacity(laststatus?.capacity?laststatus?.capacity:1)
      setSelectedDays(laststatus?.backlog?laststatus?.backlog:0);
      setSelectedOperationValue(laststatus?.operations?laststatus?.operations:1);
      setSelectedPDCapacity(laststatus?.pickup_and_delivery?laststatus?.pickup_and_delivery:100)
      setSelectedStaff(laststatus?.staffing?laststatus?.staffing:100)
      setComment(laststatus?.comment?laststatus?.comment:"")
      setSelectedIssue(laststatus?.issue?laststatus?.issue:6)
      if (laststatus.staffing === 0 || laststatus.pickup_and_delivery === 0) {
        setSelectedPDCapacity(laststatus?.pickup_and_delivery)
        setSelectedStaff(laststatus?.staffing)
      }
    }
  }, [laststatus]);
  
useEffect(()=>{
  setSelectedLocation(locationValue ? locationValue :userinfo?.location?.id)
},[userinfo,locationValue])
  const headertemplate = () => {
    return <div className="modal-title">Update Today's Status</div>;
  };

  const onHideDialog = () => {
    onHide();
    resetForm();

  };
  const StatusLocations = async () => {
    try {
      const res = await httpClient.get(`/api/locations-lookup`);
      if(res.status===200){
        setLocations(res.data)
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(()=>{
    StatusLocations();
  },[])
  const HandleSubmit=async()=>{
const selectedlocation=locations.find(loc => loc.id === selectedLocation)?.name;
    if(selectedLocation!==userinfo?.location?.id){
      setshowStatusModal(true)
      setLocationName(selectedlocation);
    }
    else{
      // setLoading(true)
      setIsSubmitting(true);
      try {
        const res= await httpClient.post('/api/location-statuses',{
          location_id:selectedLocation,
          capacity:selectedCapacity,
          operations:selectedOperationValue,
          staffing:selectedstaff,
          pickup_and_delivery:selectedPDCapacity,
          backlog:selectedDays,
          issue:selectedIssue,
          comment:comment
        })
        if(res?.status===200){
          // setLoading(false)
          setIsSubmitting(false);
          toast.current.show({severity:'success', summary: `${res?.data?.message}`, life: 2000});
          resetForm();
          setErrors()
          const locationName = res?.data?.data?.location_name 
          const locationId = res?.data?.data?.location_id 
          onRefresh(locationName, locationId);
        }
      } catch (error) {
        console.log(error)
        setErrors(error?.response?.data?.errors)
        setIsSubmitting(false);
        // setLoading(false)
      }
    }
    
  }
  const confirmSubmit=async()=>{ 
    // setLoading(true);
    setIsSubmitting(true);

          try {
            const res= await httpClient.post('/api/location-statuses',{
              location_id:selectedLocation,
              capacity:selectedCapacity,
              operations:selectedOperationValue,
              staffing:selectedstaff,
              pickup_and_delivery:selectedPDCapacity,
              backlog:selectedDays,
              issue:selectedIssue,
              comment:comment
            })
            if(res?.status===200){
              // setLoading(false)
              setIsSubmitting(false);
              toast.current.show({severity:'success', summary: `${res?.data?.message}`, life: 3000});
              const locationName = res?.data?.data?.location_name 
              const locationId = res?.data?.data?.location_id 
              onRefresh(locationName, locationId);
              resetForm()
              setErrors()

            }
          } catch (error) {
            console.log(error)
            setErrors(error?.response?.data?.errors)
            // setLoading(false)
            setIsSubmitting(false);
          }          
      }

      const resetForm= ()=>{
        setSelectedOperationValue(1);
        setSelectedCapacity(1);
        setSelectedPDCapacity(100);
        setSelectedStaff(100)
        setSelectedLocation(locationValue ? locationValue :userinfo?.location?.id);
        setSelectedIssue(6)
        setSelectedDays(0);
        setComment("");
        setErrors();
      }
      const handleSelectChange = (setter, selectedValue) => (e) => {
        if (e.value !== null && e.value !== selectedValue) {
          setter(e.value);
        }
      };
      
         
  return (
    <div className="card flex justify-content-center align-items-center status-toast">
        <Toast ref={toast} position="top-center" />
      <Dialog 
        className="status-modal"
        visible={visible}
        onHide={onHideDialog}
        header={headertemplate}
        modal={true}
        closable
        closeIcon={  <Icon name='cross-black' type='svg' />}
        closeOnEscape={false}
        dismissableMask={false}
        draggable={false}
        style={{ width: "810px", height: "740px" }}
        footer={
          <div>
            <Button
              label="Submit"
              className="secondary-status-button"
              onClick={HandleSubmit}
              disabled={isSubmitting} 
              icon={isSubmitting ? 'pi pi-spin pi-spinner' : null}
            />
          </div>
        }
      >
        <div className="grid ">
          <div className="col-12 flex flex-column gap-2 status-form">
            <label className="statusform-label">
              Location<span style={{ color: "red" }}> *</span>
            </label>
            <Dropdown
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.value)}
              options={locations}
              optionLabel="name"
              optionValue="id"
              filter
              loading={false}
              placeholder="Select location"
              filterPlaceholder="Search"
              style={{width:"232.84px"}}
            />
          </div>
          <div className="col-6 flex flex-column gap-2 operation-selected-button">
            <label className="statusform-label">
              How operations are running today?
              <span style={{ color: "red" }}> *</span>
            </label>
            <SelectButton
              value={selectedOperationValue}
              onChange={handleSelectChange(setSelectedOperationValue, selectedOperationValue)}
              options={operationitems}
              optionLabel="name"
              itemTemplate={(option) => (
                <span
                  className={`${option.className} ${
                    selectedOperationValue === option.value ? "p-highlight" : ""
                  }`}
                >
                  {option.name}
                </span>
              )}
            />
          </div>
          <div className="col-6 flex flex-column gap-2 dock-selected-button">
            <label className="statusform-label">
              How is your dock capacity?<span style={{ color: "red" }}> *</span>
            </label>
            <SelectButton
              value={selectedCapacity}
              onChange={handleSelectChange(setSelectedCapacity, selectedCapacity)}
              options={capacityitemss}
              optionLabel="name"
              itemTemplate={(option) => (
                <span
                  className={`${option.className} ${
                    selectedCapacity === option.value ? "p-highlight" : ""
                  }`}
                >
                  {option.name}
                </span>
              )}
            />
          </div>
          <div className="col-6 flex flex-column gap-2 operation-selected-button">
            <label className="statusform-label">
            How is your staffing today?
              
            </label>
            <SelectButton
              value={selectedstaff}
              onChange={handleSelectChange(setSelectedStaff, selectedstaff)}
              options={staffnumbers}
              optionLabel="name"
              itemTemplate={(option) => (
                <span
                  className={`${option.className} ${
                    selectedOperationValue === option.value ? "p-highlight" : ""
                  }`}
                >
                  {option.name}
                </span>
              )}
            />
          </div>
          <div className="col-6 flex flex-column gap-2 dock-selected-button">
            <label className="statusform-label">
            How is your P&D capacity?
            </label>
            <SelectButton
              value={selectedPDCapacity}
              onChange={handleSelectChange(setSelectedPDCapacity, selectedPDCapacity)}
              options={pdcapacity}
              optionLabel="name"
              itemTemplate={(option) => (
                <span
                  className={`${option.className} ${
                    selectedCapacity === option.value ? "p-highlight" : ""
                  }`}
                >
                  {option.name}
                </span>
              )}
            />
          </div>
          <div className="col-12 flex flex-column gap-2">
            <label className="statusform-label">
              How many days of work are you behind?
            </label>
            <div className="slider-container">
              <Slider
                value={selectedDays}
                onChange={(e) => setSelectedDays(e.value)}
                min={0}
                max={30}
                step={1}
                style={{ width: "100%" }}
              />
              <div className="slider-labels">
                <span className="slider-label start slider-text">
                  0 <br />
                  day
                </span>
                <span className="slider-label end slider-text">
                  30 <br />
                  days
                </span>
                <span
                  className="slider-value"
                  style={{
                    left: `${(selectedDays / 30) * 100}%`,
                    transform: "translateX(-50%)",
                  }}
                >
                  {selectedDays > 0 && selectedDays < 30 && (
                    <span
                      className="slider-value"
                      style={{
                        left: `${(selectedDays / 30) * 100}%`,
                        transform: "translateX(-50%)",
                      }}
                    >
                      {selectedDays} <br /> {selectedDays===1?"day":"days"}
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="grid mt-4">
          <div className="col-6 flex flex-column gap-2 status-form">
            <label className="statusform-label">Issue Faced</label>
            <Dropdown
              value={selectedIssue}
              onChange={(e) => setSelectedIssue(e.value)}
              options={issues}
              optionLabel="name"
              optionValue="code"
              loading={false}
              placeholder="Select Issue"
              style={{width:"286.11px"}}
              
            />
               {selectedOperationValue===3 &&
           <InputError message={errors?.issue} className="mt-0" style={{width:"286.11px"}}/>
         } 
          </div>
          <div className="col-6 flex flex-column gap-2 status-form">
            <label className="statusform-label">Comment</label>
            <InputText
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter Comment"
              maxLength={160}
            />
            <div className="character-count">
            <div>  {selectedOperationValue===3 &&
           <InputError message={errors?.comment} className="mt-0" style={{width:"286.11px"}}/>
         }   </div>
            <div  style={{marginTop:"2px"}}>
            <span>{comment.length}/160 characters</span>
            </div>
            </div>
                     </div>
        </div>
      </Dialog>
      <ConfirmStatusModal visible={showstatusModal} setVisible={setshowStatusModal} width="40vw" submit={confirmSubmit} title={locationName}/>
    </div>
  );
};

export default StatusUpdateDialog;
